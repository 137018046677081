import React, { useState, useRef, createRef } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import ContentBlock from "../Content/content";
import { useStaticQuery, graphql } from "gatsby";
import TextField from "./Inputs/TextField";
import TextArea from "./Inputs/TextArea";
import SelectBox from "./Inputs/SelectBox"
import Recaptcha from "./Inputs/Recaptcha";
import { DatePicker } from 'react-nice-dates'
import PostcodeField from "@components/formComponents/postcode";
import TimePicker from 'rc-time-picker';
import 'react-nice-dates/build/style.css'
import 'rc-time-picker/assets/index.css';
import { postFormData } from "./api/Api";
import { ScrollToTop, SendMail, FormValidation, gtmEvent, toCapitalize } from "../components/common/utils";
import _ from "lodash";
import { isEmpty, get, find, includes } from "lodash"
import moment from 'moment';
import { Link } from "gatsby";
import "./Form.scss";
import FieldSelect from "../components/field-select/field-select"
const HomeVisitValuation = (props) => {
    const myRef = createRef(null);
    const data = useStaticQuery(graphql`
    query{
      glstrapi {
        offices(sort:"Sort:ASC", publicationState: LIVE, where: {Publish: true}) {
          Office_Name
          Office_Email
        }
      }
    }
    `);

    let adddate = new Date();
    // add a day
    adddate.setDate(adddate.getDate() + 1);

    const recaptchaRef = useRef();
    const initFormState = {
        formname: "Home Visit Valuation Form",
        fname: '',
        lname: '',
        email: '',
        telephone: '',
        postcode: '',
        address: '',
        branch: 'Chippenham',
        department: 'Sales',
        preferredDate: adddate,
        preferredTime: moment().hour(10).minute(0).format("h:mm a"),
        message: ''
    }
    const validationFields = ['fname', 'lname', 'email', 'telephone', 'address', 'postcode'];
    const [formState, setFormState] = useState(initFormState);
    const [errors, setErrors] = useState({});
    const [errorFlag, setErrorFlag] = useState(false);
    const [showthankyou, setThankyou] = useState(false);
    const [btnProcess, setBtnProcess] = useState(false);
    const [date, setDate] = useState(adddate)
    const [time, setTime] = useState(moment().hour(10).minute(0))
    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        });
    }
    const handleChangeBranch = (e) => {
        setFormState({
            ...formState,
            "branch": e.label,
        });
    }
    const handleChangeDep = (e) => {
      setFormState({
          ...formState,
          "department": e.label,
      });
  }
    const postcodeChange = (suggestion) => {
        var selected_address = suggestion.line_1 ? suggestion.line_1+", " : "";
        selected_address += suggestion.line_2 ? suggestion.line_2+", " : "";
        selected_address += suggestion.line_3 ? suggestion.line_3+", " : "";
        selected_address += suggestion.postcode ? suggestion.postcode+", " : "";
        // sessionStorage.setItem('address', selected_address);
        // sessionStorage.setItem('postcode', suggestion.postcode);
    
        setFormState({
            ...formState,
            // location: suggestion,
            address: selected_address,
            postcode: suggestion.postcode,
        });
    }
    const selectHandelChange = (name, value) => {
        sessionStorage.setItem(name, value);

        setFormState({
            ...formState,
            [name]: value
        });
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        var validation = FormValidation(formState, validationFields);
        if (validation.status) {
            setErrorFlag(false)
            setErrors(validation.errors)
            const token = await recaptchaRef.current.executeAsync();
            if (token) {
                setBtnProcess(true)
                var postData = _.cloneDeep(formState)
                postData['extra'] = formState;
                recaptchaRef.current.reset();
                postFormData(postData).then(async apiRes => {
                    setThankyou(true)
                    setFormState(initFormState)
                    // document.querySelector('.react-autosuggest__input').value = ""
                    setBtnProcess(false);
                    // scrollTop();
                    myRef?.current?.scrollIntoView({ behavior: 'smooth'})
                    setTimeout(() => {
                        setThankyou(false)
                    }, 9000)
                    // GTM tracking event 
                    gtmEvent({ formType: 'Home visit - Property Valuation', formName: 'Home visit - Property Valuation' })
                    // lets send mail
                    postData['to_admin'] = data.glstrapi?.offices?.filter(list => list.Office_Name?.includes(formState?.branch?.toLowerCase()))[0]?.Office_Email?.toLowerCase()
                    if(postData['branch']=="Vale of Pewsey") {
                        postData['to_admin'] = "mark.loveday@strakers.co.uk";
                    }
                    if(postData['branch']=="Trowbridge") {
                        postData['to_admin'] = "trowbridge@strakers.co.uk";
                    }
                    postData['email_temp_user'] = 'homevisit_user';
                    postData['email_temp_admin'] = 'homevisit_admin';
                    postData['email_subject_user'] = 'Strakers - Home Valuation';
                    postData['email_subject_admin'] = 'Strakers - Home Valuation';
                    // postData['to_bcc'] = "marketing@strakers.co.uk";
                    // postData['from_bcc'] = "marketing@strakers.co.uk";
                    await SendMail(postData);
                });
            }
        } else {
            setErrors(validation.errors)
            setErrorFlag(true)
            // scrollTop();
            myRef?.current?.scrollIntoView({ behavior: 'smooth'})
        }
    }

    const scrollTop = () => {
        ScrollToTop(document.getElementById('form-section'), 100)
    }
    const errClass = (field) => {
        if (errors[field]) {
            return 'field-error';
        }
        return ''
    }
    var options = [];
    data?.glstrapi?.offices?.map((item) => {
        if(item.Office_Name === "sales progression")
        {

        }else {
            options.push({
                label: toCapitalize(item.Office_Name), value: item.Office_Name
            })    
        }
    })
    return (
        <React.Fragment>
            <section className='form-section' id="form-section"  ref={myRef}>
                <div className="form form--contact bg-white">
                    <div className="form__body">
                        <div className="form-row">
                            {/* {JSON.stringify(props.property_title)} */}
                            <Row>
                                <Col md={12}>
                                    {showthankyou && <div className="alert alert-success">
                                        Thank you for your interest. A member of our team will contact you shortly.
                                    </div>}
                                    {
                                        errorFlag &&
                                        <div className="alert alert-danger alert-error">Highlighted fields are required | invalid</div>
                                    }
                                    {/* {props.page_url} */}
                                    <div className='form-inputs'>
                                        <Row>
                                            <Col md={6}>
                                                <TextField
                                                    label="First Name"
                                                    name={`fname`}
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formState[`fname`]}
                                                    className={errClass(`fname`)}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    label="Last Name"
                                                    name={`lname`}
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formState[`lname`]}
                                                    className={errClass(`lname`)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <TextField
                                                    label="Email"
                                                    name={`email`}
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    className={errClass(`email`)}
                                                    value={formState[`email`]}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    type="number"
                                                    label="Phone"
                                                    name={`telephone`}
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    className={errClass(`telephone`)}
                                                    value={formState[`telephone`]}
                                                />
                                            </Col>

                                        </Row>
                                        <Row>
                                        <Col md={6}>
                                                <TextField
                                                label="Property Address"
                                                name={`address`}
                                                placeholder=""
                                                onChange={handleChange}
                                                value={formState[`address`]}
                                                className={errClass(`address`)}
                                            />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                label="Post Code"
                                                name={`postcode`}
                                                placeholder=""
                                                onChange={handleChange}
                                                value={formState[`postcode`]}
                                                className={errClass(`postcode`)}
                                            />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                            <SelectBox
                                                    label="Branch"
                                                    name={`branch`}
                                                    options={options}
                                                    onChange={(handleChangeBranch)}
                                                    selected={formState[`branch`]}
                                                    defaultValue={{
                                                      label: props?.branch
                                                        ? get(options.filter(c => c.value?.toLowerCase() === props?.branch?.toLowerCase()), '0.label')
                                                        : "Chippenham",
                                                      value: props?.branch ? props?.branch : "chippenham",
                                                    }}
                                                 
                                                />
                                            </Col>

                                            <Col md="6">
                                            <SelectBox
            label="Department"
            name={`department`}
            options={[
                { label: 'Sales', value: 'Sales' },
                { label: 'Rent', value: 'Rent' }]}
                onChange={(handleChangeDep)}
            selected={formState[`department`]}
            defaultValue={ { label: 'Sales', value: 'Sales' }}
        />
                                                {/* 
                                                    <Form.Label>
                                                    Do you want to Sell or Let the property?
                                                    </Form.Label>
                                                    <FieldSelect
                                                    isSearchable={false}
                                                    fontSize="1.6rem"
                                                    borderRadius="4px"
                                                    options={[
                                                        {
                                                        value: "Sell",
                                                        label: "Sell",
                                                        },
                                                        {
                                                        value: "Buy",
                                                        label: "Buy",
                                                        },
                                                    ]}
                                                    />
                                                </Form.Group> */}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group className='form-wrap'>
                                                    <Form.Label>Preferred Date</Form.Label>

                                                    <DatePicker date={date} onDateChange={setDate}
                                                        format="dd/MM/yyyy"
                                                        minimumDate={adddate}
                                                    >
                                                        {({ inputProps, focused }) => (
                                                            <input

                                                                className={'input form-control' + (focused ? ' -focused' : '')}
                                                                {...inputProps}
                                                            />
                                                        )}
                                                    </DatePicker>
                                                </Form.Group>

                                            </Col>
                                            <Col md="6">
                                                <Form.Group className='form-wrap'>
                                                    <Form.Label>Preferred Time</Form.Label>
                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={time}
                                                        className="time-picker form-control--time"
                                                        onChange={(e) => {
                                                            setTime(moment(e).format("h:mm a"))
                                                            setFormState({
                                                                ...formState,
                                                                preferredTime: moment(e).format("h:mm a")
                                                            });
                                                        }}
                                                        format={"h:mm a"}
                                                        use12Hours
                                                        inputReadOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <TextArea
                                            label="Message"
                                            rows={5}
                                            name="message"
                                            placeholder="Enter your message"
                                            onChange={handleChange}
                                            className="textarea"
                                            value={formState[`message`]}
                                        />
                                    </div>

                                    <Recaptcha recaptchaRef={recaptchaRef} />
                                    <div className="form__footer">
                                        <button type="submit"
                                            disabled={btnProcess}
                                            onClick={(event) => { handleSubmit(event) }}
                                            className='btn btn--yellow fz14 fw-bolder'
                                        >Send Enquiry</button>
                                        <div className='form-footer-text'>
                                            By clicking Submit, you agree to our <Link to="/terms-and-conditions/">Terms & Conditions</Link> and <Link to="/privacy-policy/">Privacy Policy</Link>.
                                        </div>
                                    </div>

                                </Col>
                            </Row>

                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )

}
export default HomeVisitValuation;